import React from "react";
import {
    ContainerInformation,
    PageHeader,
    ShipmentInfo,
    SnoCard
} from "@components";
import {
    faShip,
    faReceipt,
    faFlagSwallowtail
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ORDER_DETAILS_PAGE_TITLE } from "../shipment-details/constants";
import { useOrderDetails } from "./hooks/use-orderDetails";

import "./styles.scss";
import { useParams } from "react-router-dom";
import { OrderDetailsFetchParams } from "@stores/Ocean";

export function DeliveryDetails(): JSX.Element {
    const { isLoading, orderDetails } = useOrderDetails();

    const { deliveryNumber } = useParams<
        keyof OrderDetailsFetchParams
    >() as OrderDetailsFetchParams;
    let delivery;

    if (orderDetails) {
        delivery = orderDetails?.deliveries.find(
            (delivery) => delivery.deliveryNumber === deliveryNumber
        );
    }

    return (
        <div className="order-page">
            <PageHeader
                title={ORDER_DETAILS_PAGE_TITLE}
                icon={<FontAwesomeIcon icon={faShip} role="none" />}
            />
            {isLoading && "Loading..."}
            {!isLoading && orderDetails === null && "No Results"}
            {!isLoading && orderDetails && (
                <>
                    <div className="section">
                        <SnoCard
                            className="large-card"
                            icon={faReceipt}
                            large
                            title={ORDER_DETAILS_PAGE_TITLE}
                        >
                            <ShipmentInfo
                                label="Order #"
                                infoProps={[
                                    {
                                        value: orderDetails.orderNumber,
                                        link: `/order/${orderDetails.orderNumber}/ocean`
                                    }
                                ]}
                            />
                            <ShipmentInfo
                                label="PO #"
                                infoProps={[
                                    { value: orderDetails.purchaseOrderNumber }
                                ]}
                            />
                            <ShipmentInfo
                                label="Payment Terms"
                                infoProps={[
                                    { value: orderDetails.termsOfPayment }
                                ]}
                            />
                            <ShipmentInfo
                                label="Delivery"
                                infoProps={[
                                    { value: delivery?.deliveryNumber }
                                ]}
                            />
                            <ShipmentInfo
                                label="EAC"
                                infoProps={[{ value: orderDetails.eac }]}
                            />
                            <ShipmentInfo
                                label="Credit"
                                infoProps={[
                                    { value: orderDetails.creditStatus }
                                ]}
                            />
                            <ShipmentInfo
                                label="Origin Plant Code"
                                infoProps={[{ value: "" }]}
                            />
                        </SnoCard>
                        <SnoCard title="Status" className="small-card">
                            <>Progress Bar WIP</>
                        </SnoCard>
                    </div>
                    <div className="section">
                        <SnoCard
                            className="large-card"
                            icon={faFlagSwallowtail}
                            large
                            title={"Order Milestones"}
                        >
                            <ShipmentInfo
                                label="Planned GI Date"
                                infoProps={[
                                    {
                                        value: delivery?.plannedGiDate?.toLocaleString()
                                    }
                                ]}
                            />
                            <ShipmentInfo
                                label="Accurate VSD"
                                infoProps={[
                                    {
                                        value: delivery?.accurateVsd?.toLocaleString()
                                    }
                                ]}
                            />
                            <ShipmentInfo
                                label="ERD Date"
                                infoProps={[
                                    {
                                        value: delivery?.erdDate?.toLocaleString()
                                    }
                                ]}
                            />
                            <ShipmentInfo
                                label="Current ETD Origin"
                                infoProps={[
                                    {
                                        value: delivery?.currentEtdOrigin?.toLocaleString()
                                    }
                                ]}
                            />
                            <ShipmentInfo
                                label="Original ETD Origin"
                                infoProps={[
                                    {
                                        value: delivery?.currentEtdOrigin?.toLocaleString()
                                    }
                                ]}
                            />
                            <ShipmentInfo
                                label="Current ETA Destination"
                                infoProps={[
                                    {
                                        value: delivery?.currentEtaDestination?.toLocaleString()
                                    }
                                ]}
                            />
                            <ShipmentInfo
                                label="Rolled"
                                infoProps={[{ value: delivery?.rolled }]}
                            />
                            <ShipmentInfo
                                label="Split"
                                infoProps={[{ value: delivery?.split }]}
                            />
                        </SnoCard>
                        <SnoCard
                            icon={faShip}
                            title="Vessel"
                            className="small-card"
                        >
                            <ShipmentInfo
                                label="Vessel Name"
                                infoProps={[
                                    {
                                        value: delivery?.vesselName
                                    }
                                ]}
                            />
                            <ShipmentInfo
                                label="Carrier Name"
                                infoProps={[{ value: delivery?.carrierName }]}
                            />
                            <ShipmentInfo
                                label="Current ETA Origin"
                                infoProps={[
                                    {
                                        value: delivery?.currentEtaOrigin?.toLocaleString()
                                    }
                                ]}
                            />
                        </SnoCard>
                    </div>
                    <ContainerInformation containers={delivery?.containers} />
                </>
            )}
        </div>
    );
}
